import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private matDialog = inject(MatDialog);

  openModal(component: ComponentType<unknown>, config?: MatDialogConfig): void {
    const dialogRef = this.matDialog.open(component, config);
  }

  closeAllDialogs(): void {
    this.matDialog.closeAll();
  }
}
